import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const Beers = () => {
  const data = useStaticQuery(graphql`
    query {
      gcms {
        beers {
          name
          abv
          image {
            url
          }
          slug
          style
        }
      }
    }
  `)

  const {
    gcms: { beers },
  } = data

  return (
    <Layout pageName="beers">
      <SEO title="Our Beers" />
      <h1>
        <span>our beers</span>
      </h1>
      <div className="gallery">
        <ul>
          {beers.map(beer => (
            <li>
              <Link to={`/beers/${beer.slug}`}>
                <span className="top-title">{beer.style}</span>
                <img src={beer.image.url} alt={beer.name} />
                <span className="bottom-title">{beer.name}</span>
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </Layout>
  )
}

export default Beers
